.zo-tabs {
  &.ant-tabs {
    .ant-tabs-nav {
      margin: 0;
    }
  }
}

.invoice-tabs {
  .ant-tabs-nav-wrap {
    padding-top: 10px;
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin-right: 24px;
    }
  }
}